.FrameStatsCard {}

.DataPair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    align-items: center;
}

.DataLabel {
    color: #0000008a;
    flex-grow: 1;
}

.GoToButton {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
