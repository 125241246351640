.ScreenshotDialog {

}

.ScreenshotContainer {
    max-height: 100%;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    /* margin: 30px; */
    border: 2px solid grey;
    position: relative;
}
