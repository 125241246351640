.AssetList {
    overflow: auto;
    max-height: 100%;
}

.SearchBoxAndList {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.NoAssetsMessage {
    text-align: center;
    padding: 10px;
}
