.VideoPlayerControls {
    width: 100%;
}

.ControlContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 0 15px;
}

.ControlButtons {
    width: 210px;
    display: flex;
    justify-content: space-between;
}

.SideButton {
    width: 70px;
    text-align: center;
}

.SeekBar {
    padding: 5px 30px 5px 30px;
}
