.VideoPlayer {
    position: relative;
}

.MainPlayer, .FramePreviewImage {
    max-width: 100%;
    object-fit: contain;
    height: 100%;
}

.FramePreviewImage {
    z-index: 1000;
}

.OverlayImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    max-width: 100%;
    object-fit: contain;
    height: 100%;
}
