@import "../../Colours.module.css";

.ProjectSelector {
    background-color: var(--color-gioconda-grey-1);
    height: 100%;
}

.ListContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px;
}

.CentralColumn {
    display: flex;
    flex-direction: column;
}

.MainTitle {
    text-align: center;
    color: var(--color-gioconda-grey-3);
}

.CustomerName {
    flex-grow: 1;
    text-align: right;
    color: white;
    padding-right: 15px;
    font-size: 2rem;
}
